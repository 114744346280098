<template>
  <section class="rims-config-wheel-design">
    <rimconfig-breadcrumb
      :breadcrumb-items="breadcrumbItems"
    />
    <div
      class="content"
      :style="{
        backgroundImage: `url(${require('@/assets/images/Cover1.png')})`
      }"
    >
      <div class="overlay">
        <div class="textbox">
          <div class="first">
            <h1>{{ $t('langkey.find-your-wheels') }}</h1>
          </div>
        </div>
        <base-rimconfig-card-car-selector
          class="rimconfig-wheel-design-search"
          :title="$t('langkey.wheel-search')"
        >
          <template #body>
            <form @submit.prevent="onSearchWheelDesign">
              <div class="input-wrapper">
                <input
                  v-model="search"
                  type="search"
                  class="input__search"
                  :placeholder="$t('langkey.search-wheels-by')"
                  @input="debounceSearch"
                  @keydown.esc="clearSearch"
                >
                <v-btn
                  v-if="search"
                  icon
                  plain
                  class="button__input-clear"
                  @click="clearSearch"
                >
                  <icon-close />
                </v-btn>
                <span
                  class="icon__search"
                  @click="onSearchWheelDesign"
                >
                  <icon-search color="var(--theme-black)" />
                </span>
              </div>

              <div v-bar>
                <transition name="page">
                  <div
                    v-if="isLoading"
                    class="loading__wrapper"
                  >
                    <div class="loader">
                      <v-progress-circular
                        indeterminate
                        color="accent"
                      />
                    </div>
                  </div>
                  <div
                    v-else
                    class="wheel-design__container"
                  >
                    <div
                      v-for="(row, index) in chunkManufacturers"
                      :key="index"
                      class="manufacturer-row"
                    >
                      <div
                        v-for="(item, itemIndex) in row"
                        :key="itemIndex"
                        class="brand__item"
                      >
                        <v-btn
                          plain
                          @click="onSelectBrand(item)"
                        >
                          <img
                            v-lazy="getBrandImage(item.urlTemplate)"
                            class="brand-image"
                          >
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </form>
          </template>
        </base-rimconfig-card-car-selector>
      </div>
    </div>

    <base-rimconfig-top-brand
      show-full
      @select-brand="onSelectBrand"
    />

    <!-- <v-dialog
      v-model="searchWheelDesignDialog"
      max-width="763"
    >
      <v-card class="dialog search-wheel-design">
        <v-card-title>
          <h5 class="text">
            {{ $t('langkey.wheeldesign-modal-search-header') }}
          </h5>
          <v-btn
            icon
            plain
            class="close-dialog__button"
            @click="searchWheelDesignDialog = false"
          >
            <icon-close size="12" />
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="search"
            :label="$t('langkey.wheeldesign-modal-search-info')"
            solo
            hide-details
          >
            <template #append>
              <icon-search />
            </template>
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            class="icon"
            @click="onSearchWheelDesign"
          >
            {{ $t('langkey.wheeldesign-modal-search-button') }} <icon-search />
          </v-btn>
          <v-btn
            outlined
            plain
            class="icon"
            @click="toWheelDesignOverview"
          >
            {{ $t('langkey.wheeldesign-modal-search-all-button') }} <icon-wheel-design color="var(--theme-black)" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </section>
</template>

<style lang="scss">
@import '@/styles/pages/rims-config-wheel-design.scss';
</style>

<script>
import RimconfigBreadcrumb from '@/components/RimconfigBreadcrumb';
import { breadcrumbItems } from './configs.js';
// import IconWheelDesign from '@/components/icons/IconWheelDesign';
import { mapState } from 'vuex';
import { getImageBySize } from '@/utils';
import _ from 'lodash';
import debounce from 'lodash/debounce';

export default {
  components: {
    RimconfigBreadcrumb,
    // IconWheelDesign,
  },
  data() {
    return {
      search: '',
      showSearchBar: false,
      searchWheelDesignDialog: false,
      isLoading: false,
      filteredManufacturers: [],
    };
  },
  computed: {
    ...mapState('rimconfigBase', ['wheelManufacturers']),
    breadcrumbItems() {
      return breadcrumbItems;
    },
    chunkManufacturers() {
      if (_.isEmpty(this.filteredManufacturers)) {
        return [];
      }

      return _.chunk(Object.values(this.filteredManufacturers), 3);
    },
  },
  created() {
    if (this.wheelManufacturers.length > 0) {
      this.filteredManufacturers = this.wheelManufacturers;
      return;
    }
    this.isLoading = true;
    this.$store.dispatch('rimconfigBase/getWheelManufacturers')
      .finally(() => {
        this.isLoading = false;
        this.filteredManufacturers = this.wheelManufacturers;
      });
  },
  methods: {
    onSelectBrand(item) {
      this.$store.dispatch('rimconfigBase/getWheelDesigns', {
        manufacturers: item.name,
      })
        .then(() => {
          this.$router.push(this.$i18nRoute({
            name: 'rims-config-wheelDesign-overview',
            query: {
              brand: item.name,
            },
          }));
        })
        .catch((err) => {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: this.$t('langkey.error-wheel-design'),
            message: err,
          });
        });
    },
    debounceSearch: debounce(function(e) {
      if (this.search.length) {
        this.filteredManufacturers = Object.values(this.wheelManufacturers).filter((item) => {
          return item.name.toLowerCase().includes(this.search.toLowerCase().trim());
        });
      } else {
        this.filteredManufacturers = this.wheelManufacturers;
      }
    }, 400),
    clearSearch() {
      this.search = '';
      this.filteredManufacturers = this.wheelManufacturers;
    },
    onSearchWheelDesign() {
      this.isLoading = true;
      this.$store.dispatch('rimconfigBase/getWheelsByQuery', {
        query: this.search,
      })
        .then((res) => {
          this.isLoading = false;

          const len = res.data.length;
          if (len === 0) {
            this.$store.dispatch('dialog/setShowErrorDialog', {
              status: true,
              title: this.$t('langkey.wheels-not-found'),
              message: this.$t('langkey.please-try-other-parameters'),
            });
            this.clearSearch();
          } else if (len === 1) {
            // search by ean or man results in one wheelTag
            this.$router.push(
              this.$i18nRoute({
                name: 'rims-config-wheelDesign_design-details',
                query: {
                  search: this.search,
                },
              }),
            );
          } else {
            this.$router.push(
              this.$i18nRoute({
                name: 'rims-config-wheelDesign-overview',
                query: {
                  search: this.search,
                },
              }),
            );
          }
        });
    },
    toWheelDesignOverview() {
      this.$router.push(
        this.$i18nRoute({
          name: 'rims-config-wheelDesign-overview',
        }),
      );
    },
    getBrandImage(image) {
      return getImageBySize(image, 200, 80);
    },
  },
};
</script>