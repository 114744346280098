export const breadcrumbItems = [
  {
    key: 'brand',
    label: 'langkey.breadcrumb-brand',
  },
];

export const sliderImages = [
  require('@/assets/images/Cover1.png'),
  require('@/assets/images/Cover1.png'),
  require('@/assets/images/Cover1.png'),
];